.glassBox {
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.61);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

#root, body, html {
  height: 100%;
}

body{
  background: url(../src/utils/assets/back.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body:after{
  content:"";
  position:fixed; /* stretch a fixed position to the whole screen */
  top:0;
  height:100vh; /* fix for mobile browser address bar appearing disappearing */
  left:0;
  right:0;
  z-index:-1; /* needed to keep in the background */
  background: url(../src/utils/assets/back.jpg) center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.icon{
  border: 1px solid #2A2E41;
  padding: 10px;
  padding-right: 24px;
  margin-left: 10px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 15px;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  min-height: 14px;
  color: #2A2E41;
}

.buttonCursor:hover {
  cursor: pointer;
}

.file-input-button {
  background-color: #F12108;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-input-button:hover {
  background-color: white;
  color:black
}

.file-input {
  display: none;
}