.beconImage{
    max-width: 1100px;
    max-height: 900px;
}

.btn-location{
    border: 1px solid black;
    border-radius: 4px;
    padding: 9px;
    padding-right: 24px;
    margin-left: 15px;
    margin-right: 15px;

    width: 15px;
    height: 38px;
    color: black;

  }